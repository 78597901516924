import { useTranslation } from 'react-i18next'
import IconButton from '~/components/IconButton'
import ArrowRight from '~/components/icons/ArrowRight'
import Link from '~/components/Link'
import Typography from '~/components/Typography'
import { ROUTES } from '~/constants/routes'

export type LearnMoreLinkProps = {
  to: ValuesOf<typeof ROUTES>
}

const LearnMoreLink = ({ to }: LearnMoreLinkProps) => {
  const { t } = useTranslation()

  return (
    <Link
      to={to}
      className="group flex w-fit items-center gap-x-4 text-white/80 hover:text-white"
    >
      <IconButton
        component="span"
        color="transparent"
        className="border-white/25 text-current transition-colors group-hover:border-white"
      >
        <ArrowRight fill="white" width={16} height={16} />
      </IconButton>
      <Typography component="span">{t('common:learnMore')}</Typography>
    </Link>
  )
}

export default LearnMoreLink
